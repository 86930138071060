import React, { useContext, useEffect, useState } from "react";
import { Select } from "@mantine/core";
import instance from "../axios/global";
import { useNavigate } from "react-router-dom";
import edit from "../assets/Edit.svg";
import trash from "../assets/Trash-2.svg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import LoaderComp from "../components/LoaderComp";
import { Modal } from "@mantine/core";
import NavBdc from "../components/NavBdc";
import { AuthContext } from "../auth/auth";
import Papa from "papaparse";
import { useQuery } from "react-query";

const Bdc = () => {
  const {
    isLoading,
    error,
    data: bdcList,
    refetch,
  } = useQuery("bdcList", () =>
    instance.get("/bdc/all").then((response) => response.data)
  );
  console.log("🚀 ~ Bdc ~ bdcList:", bdcList);

  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [opened, setOpened] = useState(false);
  const navigate = useNavigate();
  const [idBdc, setIdBdc] = useState("");
  const [filtres, setFiltres] = useState({
    nom: "",
    dateDebut: "",
    estimatedAmount: "",
    status: "",
    nomClient: "",
    montantTTC: "",
  });

  const [sortDirection, setSortDirection] = useState("desc");
  const [statutFilter, setStatutFilter] = useState("");
  const [typeFilter, setTypeFilter] = useState("");
  const [optionsType, setOptionsType] = useState([]);

  const customSort = (a, b) => {
    const { sortColumn, sortDirection } = filtres;
    if (sortDirection === "asc") {
      return a[sortColumn] < b[sortColumn] ? -1 : 1;
    } else {
      return a[sortColumn] > b[sortColumn] ? -1 : 1;
    }
  };
  const { authState } = useContext(AuthContext);
  const [isVisible, setIsVisible] = useState(false);
  const handleRightClick = (event, id) => {
    // Vérifier si la touche CMD (ou Ctrl sur PC) est enfoncée
    const cmdOrCtrlPressed = event.metaKey || event.ctrlKey;

    // Si CMD/Ctrl est enfoncé, ouvrir le lien dans un nouvel onglet
    if (cmdOrCtrlPressed) {
      // Empêcher le comportement par défaut du clic droit
      event.preventDefault();
      // Construire l'URL à ouvrir dans un nouvel onglet
      const url = `/bdc/update/${id}`; // Remplacez cette URL par celle que vous souhaitez ouvrir dans un nouvel onglet
      // Ouvrir l'URL dans un nouvel onglet
      const newTab = window.open(url, "_blank", "noopener,noreferrer"); // Add the button to the page

      if (newTab) {
        newTab.opener = window; //
      }
    }
  };

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  const scrollToTop = () => {
    // Fonction pour faire défiler jusqu'en haut de la page
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Pour une animation de défilement en douceur
    });
  };

  // Attachez un gestionnaire d'événements de défilement pour afficher/masquer le bouton
  window.addEventListener("scroll", handleScroll);

  useEffect(() => {
    const fetchOptionType = async () => {
      setLoading(true);
      try {
        const res = await instance.get("/bdc/alltype");
        setOptionsType(res.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.log("error", error);
      }
    };
    fetchOptionType();
  }, []);

  const filteredBySearchBdcList = bdcList?.bdcs
    ? bdcList?.bdcs?.filter((bdc) => {
        const query = searchQuery.toLowerCase();
        return (
          bdc?.number?.toString().toLowerCase().includes(query) ||
          bdc?.name?.toLowerCase().includes(query) ||
          bdc?.client_name?.toLowerCase().includes(query)
        );
      })
    : [];

  const filteredBdcList = filteredBySearchBdcList.filter((element) => {
    // Filtrer par "statut"
    const statusFilterMatch =
      statutFilter === "" ||
      (statutFilter === "TA" && element.totalAttribution === 100) ||
      (statutFilter === "NA" && element.totalAttribution === 0) ||
      (statutFilter === "PA" &&
        element.totalAttribution > 0 &&
        element.totalAttribution < 100);

    // Filtrer par "type"
    const typeFilterMatch =
      typeFilter === "" || element.main_expertise === typeFilter;

    // Inclure l'élément dans les résultats si le statut et/ou le type correspondent aux filtres sélectionnés
    return statusFilterMatch && typeFilterMatch;
  });
  const handleDeleteBdc = async (id) => {
    try {
      await instance.delete(`/bdc/delete/${id}`);
      setOpened(false);
      toast.success("Bon de commande supprimé.", {
        position: toast.POSITION.TOP_CENTER,
        className: "toast-message",
      });
      refetch();
    } catch (error) {
      toast.error("Error !", {
        position: toast.POSITION.TOP_LEFT,
      });
      console.log("error :>> ", error);
    }
  };

  //remplacer ici tableauTriéEtFiltré par filteredBdcList ou inversement quand il faudra avoir les bons elements avec plusieurs filtres
  const sortedAndFilteredBdcList = [...filteredBdcList].sort(customSort);
  const filteredBdcListByStatus = sortedAndFilteredBdcList.filter((element) => {
    if (filtres.status === "") {
      return true;
    } else if (filtres.status === "TA" && element.totalAttribution === 100) {
      return true;
    } else if (filtres.status === "NA" && element.totalAttribution === 0) {
      return true;
    } else if (
      filtres.status === "PA" &&
      element.totalAttribution > 0 &&
      element.totalAttribution < 100
    ) {
      return true;
    }
    return false;
  });

  const rows =
    filteredBdcListByStatus &&
    filteredBdcListByStatus.map((element) => {
      const id = element.id;
      const startDate = moment(element.start_date, "YYYY-MM").format("MM-YYYY");
      const endDate = moment(element.end_date).format("MM-YYYY");

      let status = ""; // Statut par défaut
      let statusClass = ""; // Classe CSS par défaut

      if (element.totalAttribution === 0) {
        status = "Non attribué";
        statusClass = "status-non-attribue";
      } else if (element.totalAttribution === 100) {
        status = "Attribué";
        statusClass = "status-attribue";
      } else {
        status = "Attribué à " + element.totalAttribution + "%";
        statusClass = "status-partiellement-attribue";
      }

      return (
        <tr
          key={id}
          onClick={(event) => {
            const cmdOrCtrlPressed = event.metaKey || event.ctrlKey;
            if (!cmdOrCtrlPressed) {
              // navigate(`/bdc/update/${id}`);
            }
            handleRightClick(event, id);
          }}
          // onContextMenu={(event) => {
          //   // Empêcher le comportement par défaut du clic droit
          //   event.preventDefault();
          //   // Récupérer l'URL ou le chemin vers lequel vous souhaitez rediriger
          //   const url = "/bdc/update/" + element.id; // Remplacez cette URL par celle que vous souhaitez ouvrir dans un nouvel onglet
          //   // Ouvrir l'URL dans un nouvel onglet
          //   window.open(url, "_blank");
          // }}
        >
          <td>{element?.number ?? 0}</td>
          <td
            style={{
              maxWidth: "200px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {element?.client_name}
          </td>
          <td
            style={{
              maxWidth: "300px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            title={element?.name}
          >
            {element?.name ? `${element.name.substring(0, 18)}...` : ""}
          </td>
          <td>{element?.main_expertise ? element.main_expertise : ""}</td>
          <td>{element?.amount_ttc?.toLocaleString() ?? 0}€</td>
          <td>{startDate}</td>
          <td>{endDate}</td>
          <td>{element?.estimated_amount?.toLocaleString() ?? 0}€</td>
          <td>
            <div className={statusClass}>{status}</div>
          </td>

          <td>
            <img
              onClick={() => {
                navigate(`/bdc/update/${id}`);
              }}
              className="icon-action"
              src={edit}
              alt="edit"
            />

            <img
              className="icon-action"
              src={trash}
              alt="delete"
              onClick={() => {
                setOpened(true);
                setIdBdc(id);
              }}
            />
          </td>
        </tr>
      );
    });
  const optionsStatus = [
    { value: "", label: "Tous les statuts", name: "" },
    { value: "TA", label: "Totalement Attribué", name: "TA" },
    { value: "NA", label: "Non Attribué", name: "NA" },
    { value: "PA", label: "Partiellement Attribué", name: "PA" },
  ];
  /*   const optionsType = [
    { value: "", label: "Tous les types", name: "" },
    { value: "SEO", label: "SEO", name: "SEO" },
    { value: "SEA", label: "SEA", name: "SEA" },
    { value: "MIX", label: "MIX", name: "MIX" },
  ]; */
  const afficherBoutonFunc = () => {
    if (
      authState.email === "massinissa@yuriandneil.com" ||
      process.env.NODE_ENV === "development" ||
      authState.email === "benoit@yuriandneil.com"
    ) {
      return true;
    } else {
      return false;
    }
  };
  const afficherBouton = afficherBoutonFunc();
  const getStatusLabel = (element) => {
    // Determine the status label based on the element's totalAttribution
    if (element.totalAttribution === 0) {
      return "Non attribué";
    } else if (element.totalAttribution === 100) {
      return "Attribué";
    } else {
      return `Attribué à ${element.totalAttribution}%`;
    }
  };

  const exportToCsv = () => {
    const filteredBdcListForExport = filteredBdcListByStatus.map((element) => ({
      // Map the fields you want to include in the CSV
      "Numéro affaire": element.number,
      Client: element.client_name,
      Nom: element.name,
      Expertise: element.main_expertise,
      "Montant ttc": element.amount_ttc,
      "Date de début": element.start_date,
      "Date de fin": element.end_date,
      "Marge estimée ": element.estimated_amount,
      status: getStatusLabel(element),
    }));

    // Convert the filtered BDCs to CSV format using PapaParse
    const csv = Papa.unparse(filteredBdcListForExport, {
      header: true,
      skipEmptyLines: true,
    });

    // Create a Blob with the CSV data
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    // Create a download link and trigger a click to download the CSV file
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "filtered_bdcs.csv");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };
  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>An error occurred: {error.message}</div>;

  return (
    <div className="bdc-page">
      <header>
        <h1>
          Liste des bons de commande |{" "}
          {`${
            bdcList?.totalNaPaAmount.toLocaleString("fr-FR", {
              style: "currency",
              currency: "EUR",
            }) || 0
          }`}{" "}
          Restant a attribué{" "}
        </h1>
      </header>
      <NavBdc />
      <section>
        <Modal
          opened={opened}
          onClose={() => setOpened(false)}
          overlayOpacity={0.55}
          overlayBlur={3}
          transition="fade"
          transitionDuration={600}
          transitionTimingFunction="ease"
          size={650}
          padding={50}
        >
          <div className="container-confirm-delete">
            <div className="confirm-msg-delete">
              <p> Etes-vous sûr de vouloir supprimer cette affaire ?</p>
              <span>
                Vous ne pourrez plus revenir en arrière. Le bon de commande sera
                définitivement supprimer ainsi que toutes ses attributions
              </span>
            </div>
            <div className="div-button-choice">
              <button className="btn-no" onClick={() => setOpened(false)}>
                Annuler
              </button>
              <button
                className="btn-yes"
                onClick={() => handleDeleteBdc(idBdc, console.log(idBdc))}
              >
                Supprimer
              </button>
            </div>
          </div>
        </Modal>
        <div className="import-search-div">
          <div className="search-create">
            {bdcList.bdcs.length !== 0 ? (
              <div>
                <input
                  className="search-bar"
                  type="text"
                  placeholder="Recherche bon de commande"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            ) : (
              ""
            )}

            <div style={{ display: "flex" }}>
              <div className="status-filter">
                <Select
                  w={200}
                  style={{ cursor: "pointer", marginRight: " 12px" }}
                  placeholder="Filtrer par expertise"
                  color="gray.0"
                  id="statusFilter"
                  // className="custom-select"
                  data={optionsType}
                  onChange={setTypeFilter}
                />
              </div>
              <div className="status-filter">
                <Select
                  w={200}
                  style={{ cursor: "pointer", marginRight: " 12px" }}
                  placeholder="Filtrer par statuts"
                  color="gray.0"
                  id="statusFilter"
                  // className="custom-select"
                  data={optionsStatus}
                  onChange={setStatutFilter}
                />
              </div>
              <div>
                {bdcList.bdcs.length !== 0 && (
                  <button
                    className="btn-import-bdc"
                    style={{ cursor: "pointer", marginRight: "12px" }}
                    onClick={exportToCsv}
                  >
                    Exporter en CSV
                  </button>
                )}
              </div>
              <div>
                {afficherBouton && (
                  <button
                    className="btn-import-bdc"
                    style={{
                      cursor: "pointer",
                      marginRight: "12px",
                      backgroundColor: "gray",
                    }}
                    onClick={() => {
                      navigate(`/bdc/importAttribution`);
                    }}
                  >
                    Importer Attribution
                  </button>
                )}
              </div>
              <div>
                {afficherBouton && (
                  <button
                    className="btn-import-bdc"
                    style={{ cursor: "pointer", backgroundColor: "gray" }}
                    onClick={() => {
                      navigate(`/bdc/importBDC`);
                    }}
                  >
                    Importer BDC
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        {loading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginTop: "2rem",
              marginBottom: "5rem",
            }}
          >
            <LoaderComp />
          </div>
        ) : (
          <div className="container-table">
            {bdcList.bdcs.length > 0 ? (
              <table className="table">
                <thead className="thead-bdcs sticky-table-head">
                  <tr>
                    <th
                      className="th"
                      onClick={() => {
                        // Inverser la direction du tri à chaque clic
                        const newSortDirection =
                          sortDirection === "asc" ? "desc" : "asc";
                        setSortDirection(newSortDirection);
                        setFiltres({
                          ...filtres,
                          sortColumn: "number",
                          sortDirection: newSortDirection,
                        });
                      }}
                    >
                      Numéro affaire{" "}
                      <span>{sortDirection === "asc" ? " ↓" : " ↑"}</span>
                    </th>
                    <th
                      className="th"
                      onClick={() => {
                        // Inverser la direction du tri à chaque clic
                        const newSortDirection =
                          sortDirection === "asc" ? "desc" : "asc";
                        setSortDirection(newSortDirection);
                        setFiltres({
                          ...filtres,
                          sortColumn: "client_name",
                          sortDirection: newSortDirection,
                        });
                      }}
                    >
                      Client{" "}
                      <span>{sortDirection === "asc" ? " ↓" : " ↑"}</span>
                    </th>
                    <th
                      className="th"
                      onClick={() => {
                        // Inverser la direction du tri à chaque clic
                        const newSortDirection =
                          sortDirection === "asc" ? "desc" : "asc";
                        setSortDirection(newSortDirection);
                        setFiltres({
                          ...filtres,
                          sortColumn: "name",
                          sortDirection: newSortDirection,
                        });
                      }}
                    >
                      Nom <span>{sortDirection === "asc" ? " ↓" : " ↑"}</span>
                    </th>
                    <th>Expertise</th>
                    <th
                      className="th"
                      onClick={() => {
                        // Inverser la direction du tri à chaque clic
                        const newSortDirection =
                          sortDirection === "asc" ? "desc" : "asc";
                        setSortDirection(newSortDirection);
                        setFiltres({
                          ...filtres,
                          sortColumn: "amount_ttc",
                          sortDirection: newSortDirection,
                        });
                      }}
                    >
                      Montant TTC{" "}
                      <span>{sortDirection === "asc" ? " ↓" : " ↑"}</span>
                    </th>
                    <th
                      className="th"
                      onClick={() => {
                        // Inverser la direction du tri à chaque clic
                        const newSortDirection =
                          sortDirection === "asc" ? "desc" : "asc";
                        setSortDirection(newSortDirection);
                        setFiltres({
                          ...filtres,
                          sortColumn: "start_date",
                          sortDirection: newSortDirection,
                        });
                      }}
                    >
                      Date de début{" "}
                      <span>{sortDirection === "asc" ? " ↓" : " ↑"}</span>
                    </th>
                    <th
                      className="th"
                      onClick={() => {
                        // Inverser la direction du tri à chaque clic
                        const newSortDirection =
                          sortDirection === "asc" ? "desc" : "asc";
                        setSortDirection(newSortDirection);
                        setFiltres({
                          ...filtres,
                          sortColumn: "end_date",
                          sortDirection: newSortDirection,
                        });
                      }}
                    >
                      Date de fin{" "}
                      <span>{sortDirection === "asc" ? " ↓" : " ↑"}</span>
                    </th>
                    <th
                      className="th"
                      onClick={() => {
                        // Inverser la direction du tri à chaque clic
                        const newSortDirection =
                          sortDirection === "asc" ? "desc" : "asc";
                        setSortDirection(newSortDirection);
                        setFiltres({
                          ...filtres,
                          sortColumn: "estimated_amount",
                          sortDirection: newSortDirection,
                        });
                      }}
                    >
                      Marge estimée{" "}
                      <span>{sortDirection === "asc" ? " ↓" : " ↑"}</span>
                    </th>
                    <th>Statut</th>
                  </tr>
                </thead>
                <tbody>{rows}</tbody>
              </table>
            ) : (
              <p>Pas de bdc</p>
            )}
          </div>
        )}
        {isVisible && (
          <button className="scroll-to-top" onClick={scrollToTop}>
            ↑
          </button>
        )}
      </section>
    </div>
  );
};

export default Bdc;
